import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { OnboardingService } from '../onboarding.service';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
    `
      .loading-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(
          0,
          0,
          0,
          0.5
        ); /* semi-transparent black background */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999; /* Ensure it's on top of other content */
      }
    `,
  ],
})
export class LoginComponent {
  showSpinner = false;
  router = inject(Router);
  onboardingService = inject(OnboardingService);
  _snackBar = inject(MatSnackBar);
  authService = inject(AuthService);
  translateService = inject(TranslateService);
  public loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  constructor() {}

  async login() {
    if (this.loginForm.invalid) return;
    const searchParams = new URLSearchParams(window.location.search);
    const formData = this.loginForm.getRawValue();
    if (formData.username && formData.password) {
      this.showSpinner = true;
      this.onboardingService.setUsername(formData.username);
      try {
        await this.authService.login(formData.username, formData.password);
        this.router.navigate([searchParams.has('redirect') ? searchParams.get('redirect') : 'home'], { replaceUrl: true });
        const language = this.translateService.getBrowserLang()
          ? this.translateService.getBrowserLang()
          : 'en';
        const user = await Auth.currentAuthenticatedUser();
        this.translateService.use(
          user.attributes?.['locale'] ? user.attributes?.['locale'] : language
        );
      } catch (e: any) {
        this.showSpinner = false;
        this._snackBar.open(e.message, 'Close', { duration: 5000 });
        if (e.code === 'UserNotConfirmedException') {
          this.router.navigate(['/onboarding/validation'], {
            replaceUrl: true,
          });
        }
      }
    }
  }
}
