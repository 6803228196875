<div class="flex flex-col justify-center items-center mt-6"
     [ngStyle]="movilFragment === 'movil' ? {'padding-left': '16px', 'padding-right': '16px'} : {}">
  <img width="128px" [src]="getIconPath()" />

  <h2 *ngIf="statusNotification === 'ko' || statusNotification === 'esim-ko'" class="text-center font-semibold mb-0">
    Ooops!
  </h2>

  <h2 class="text-center font-semibold"
      [ngClass]="{'mt-0': statusNotification !== 'ok' && statusNotification !== 'esim-ok'}">
    {{
    statusNotification === 'ok'
    ? ('CHECKOUT.OK.TITLE' | translate)
    : statusNotification === 'esim-ok'
    ? ('CHECKOUT.OK-ESIM.TITLE' | translate)
    : statusNotification === 'esim-ko'
    ? ('CHECKOUT.KO-ESIM.TITLE' | translate)
    : ('CHECKOUT.KO.TITLE' | translate)
    }}
  </h2>

  <p class="text-base text-center">
    {{
    statusNotification === 'ok'
    ? ('CHECKOUT.OK.DESC' | translate)
    : statusNotification === 'esim-ok'
    ? ('CHECKOUT.OK-ESIM.DESC' | translate)
    : statusNotification === 'esim-ko'
    ? ('CHECKOUT.KO-ESIM.DESC' | translate)
    : ('CHECKOUT.KO.DESC' | translate)
    }}
  </p>

  <p *ngIf="statusNotification === 'ko' || statusNotification === 'esim-ko'" class="mt-8 font-semibold text-xl">
    {{
    'CHECKOUT.KO.TRY' | translate
    }}
  </p>

  <app-button *ngIf="movilFragment !== 'movil' && movilFragment !== 'esim'" class="mt-20" routerLink="/recharges">
    {{
 'CHECKOUT.BUTTON' | translate
    }}
  </app-button>

  <app-button *ngIf="movilFragment === 'esim'" class="mt-20" routerLink="/esim">
    {{
 'CHECKOUT.BUTTON' | translate
    }}
  </app-button>

  <app-button *ngIf="movilFragment === 'movil'" class="mt-20" (click)="close()">
    {{
 'CHECKOUT.BUTTON' | translate
    }}
  </app-button>
</div>
