import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, Hub } from 'aws-amplify';
import { BehaviorSubject, map } from 'rxjs';

export interface UserExtraData {
  name: string;
  email: string;
  phone_number: string;
  locale: string;
  "custom:signalwire_ok": string;
}

export interface AuthStatus {
  loggedIn: boolean;
  username?: string | null;
  id?: string | null;
  email?: string | null;
  name?: string | null;
  phone_number?: string | null;
}

const EMPTY_LOGGED: AuthStatus = {
  loggedIn: false,
  username: '',
  id: '',
  email: '',
  name: '',
  phone_number: '',
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  router = inject(Router);
  private authStatusSubject = new BehaviorSubject<AuthStatus>(EMPTY_LOGGED);
  public authStatus$ = this.authStatusSubject.asObservable();

  readonly isLoggedIn$ = this.authStatus$.pipe(map((state) => state.loggedIn));

  constructor() {
    Auth.currentAuthenticatedUser().then(
      (user: any) => this.setUser(user),
      (_err) => this.authStatusSubject.next(EMPTY_LOGGED)
    );

    Hub.listen('auth', async ({ payload: { event, data, message } }) => {
      switch (event) {
        case 'signedIn':
        case 'signIn':
        case 'autoSignIn':
          // this.setUpUser(data);
          break;
        case 'updateUserAttributes':
          //console.log('updateUserAttributes');
          break;
        case 'signedOut':
          //console.log('user have been signedOut successfully.');
          await this.updateUserStateOffline();
          break;
        case 'tokenRefresh':
          console.log('auth tokens have been refreshed.');
          break;
        case 'tokenRefresh_failure':
          //console.log('failure while refreshing auth tokens.');
          await this.updateUserStateOffline();
          break;
        case 'signInWithRedirect':
          //console.log('signInWithRedirect API has successfully been resolved.');
          break;
        case 'signInWithRedirect_failure':
          //console.log('failure while trying to resolve signInWithRedirect API.');
          await this.updateUserStateOffline();
          break;
        case 'customOAuthState':
          //console.info('custom state returned from CognitoHosted UI');
          break;
        default:
          //await this.updateUserStateOffline();
          this.authStatusSubject.next(EMPTY_LOGGED);
      }
    });
  }

  private setUpUser(data: any) {
    this.setUser(data);
    this.router.navigate(['home'], { replaceUrl: true });
  }

  private updateUserData(data: any) {
    const currentUser = this.getUser();
    this.authStatusSubject.next({ ...currentUser, ...data });
  }

  getUser() {
    return this.authStatusSubject.getValue();
  }

  private setUser(user: any) {
    if (!user) {
      return;
    }

    const {
      attributes: { sub: id, email, name, phone_number },
      username,
    } = user;

    this.authStatusSubject.next({
      loggedIn: true,
      id,
      username,
      email,
      name,
      phone_number,
    });
  }

  async login(username: string, password: string) {
    this.authStatusSubject.next(EMPTY_LOGGED);
    try {
      const user = await Auth.signIn(username, password);
      const userAttributes = await Auth.userAttributes(user);

      // const estadoAttribute = userAttributes.find(
      //   (attr) => attr.Name === 'custom:Estado'
      // );
      // if (estadoAttribute && estadoAttribute.Value === 'ONLINE') {
      //   await Auth.signOut();
      //   throw new Error('Your account is already online, please close the other session.');
      // }
      const suspendidoAttribute = userAttributes.find(
        (attr) => attr.Name === 'custom:Banned'
      );
      if (suspendidoAttribute && suspendidoAttribute.Value === '1') {
        await Auth.signOut();
        throw new Error('Your account has been suspended. Please contact support.');
      }

      //await this.updateUserState(user, 'ONLINE');
      this.setUser(user);
      return user;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await this.updateUserState(user, 'OFFLINE');
    } catch (error) {
      //console.error('Error updating user state to OFFLINE:', error);
    }
    await Auth.signOut();
    this.authStatusSubject.next({ loggedIn: false });
    this.router.navigate(['onboarding/login']);
    window.location.reload();
  }

  async updateUserState(user: any, state: string) {
    try {
      const userAttributes = await Auth.userAttributes(user);
      const estadoAttribute = userAttributes.find(
        (attr) => attr.Name === 'custom:Estado'
      );

      if (estadoAttribute) {
        await Auth.updateUserAttributes(user, {
          'custom:Estado': state,
        });
      } else {
        await Auth.updateUserAttributes(user, {
          'custom:Estado': state,
        });
      }
    } catch (error) {
      //console.error(`Error updating user state to ${state}:`, error);
    }
  }

  private async updateUserStateOffline() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await this.updateUserState(user, 'OFFLINE');
    } catch (error) {
      //console.error('Error updating user state to OFFLINE:', error);
    }
  }

  async register(email: string, username: string, password: string) {
    this.authStatusSubject.next(EMPTY_LOGGED);
    const attributes = {
      email: '',
      'custom:Banned': '0',
      'custom:Estado':''
    };
    attributes.email = email.toLowerCase();
    return await Auth.signUp({
      username: username.toLowerCase(),
      password,
      attributes,
      autoSignIn: {
        enabled: true,
      },
    });
  }

  async resetPassword(username: string): Promise<void> {
    return await Auth.forgotPassword(username);
  }

  async confirmSignUp(username: string, validationCode: string) {
    this.authStatusSubject.next(EMPTY_LOGGED);
    return await Auth.confirmSignUp(username, validationCode);
  }

  resendSignUp(username: string) {
    return Auth.resendSignUp(username);
  }

  async forgotPasswordSubmit(username: string, code: string, newPassword: string) {
    this.authStatusSubject.next(EMPTY_LOGGED);
    return await Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  async deleteAccount() {
    await Auth.deleteUser();
    this.authStatusSubject.next({ loggedIn: false });
    this.router.navigate(['onboarding/login']);
    window.location.reload();
  }

  async updateUser(data: Partial<UserExtraData>) {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, data);
    this.updateUserData(data);
  }

  async refresh() {
    Auth.currentAuthenticatedUser({ bypassCache: true });
  }
}
