import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (message: any) => void;
    };
  }
}

@Component({
  selector: 'app-checkout-notification',
  templateUrl: './checkout-notification.component.html',
  styleUrls: []
})
export class CheckoutNotificationComponent implements OnInit {

  statusNotification: string = '';
  movilFragment: string = '';
  ruta: any; // Variable para almacenar la ruta
  url: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    // Obtener la URL actual
    const currentUrl = window.location.href;
    
    // Verificar si la URL contiene parámetros
    this.url = currentUrl.includes('?');

    // Obtener el array de la ruta actual y asignarlo a la variable ruta
    this.ruta = this.route.snapshot;
    console.log(this.ruta); // Mostrar en la consola el array ruta

    this.statusNotification = this.route.snapshot.params['status'];
    console.log(this.statusNotification); // Mostrar en la consola el valor de statusNotification

    if (this.statusNotification !== "ok" && this.statusNotification !== "ko") {
      this.statusNotification = this.ruta.url[1].path;
    }

    // Obtener el fragmento de la URL y asignarlo a movilFragment
    this.movilFragment = this.route.snapshot.fragment ?? '';
    console.log(this.movilFragment);

    if (this.movilFragment.includes('?')) {
      this.movilFragment = this.movilFragment.split('?')[0];
    }


    if (this.movilFragment.includes('esim')) {
      if (this.statusNotification === 'ok') {
        this.statusNotification = 'esim-ok';
      } else if (this.statusNotification === 'ko') {
        this.statusNotification = 'esim-ko';
      }
    } else {

      if (this.statusNotification !== 'ok' && this.statusNotification !== 'ko') {
        this.statusNotification = 'ko';
      }
    }

    //console.log(this.movilFragment);
  }

  getIconPath(): string {
    switch (this.statusNotification) {
      case 'ok':
      case 'esim-ok':
        return './assets/icons/ok.svg';
      case 'ko':
      case 'esim-ko':
        return './assets/icons/ko.svg';
      default:
        return './assets/icons/ko.svg';
    }
  }
  


  close() {
    // @ts-ignore
    window.ReactNativeWebView.postMessage("close")
  }
}
