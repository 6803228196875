import { inject, Injectable } from '@angular/core';
import { AWSLambda } from "../AWSLambda.service";
import { ConfigService } from "../config.service";
import { Account, APIService, CreateTransactionInput, Currency, Transaction } from "../API.service";
import { AuthService } from "./auth.service";
import { ThisReceiver } from '@angular/compiler';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Response } from 'aws-sdk';

@Injectable({
  providedIn: 'root'
})
export class TPVService {
  private lambda = inject(AWSLambda);
  private configService = inject(ConfigService);
  private api = inject(APIService);
  private authService = inject(AuthService);
  private authenticatorSvc = inject(AuthenticatorService);



  async getinfo(amount: string, accion: string, pakageId: string, opciones: string) {
    const user = await this.authenticatorSvc.user
    return await this.lambda.getLambdaFunction(
      'lambda',
      '/tpv/config',
      {
        queryStringParameters: {
          amount: amount,
          owner: user.attributes?.["sub"],
          email: user.attributes?.["email"],
          username: this.authService.getUser().username ?? '',
          source: 'web',
          action: accion,
          pakageId:pakageId,
          opciones:opciones
        },
      }
    )
  }


  async createFormAndSendForm(amount: string, action: string, pakageId: string, accId: string) {

    const response = await this.getinfo(amount, action,pakageId,accId)
    //console.log(response)

    const bbvaData = JSON.parse(response); //Obtener los datos de BBVA de la respuesta de DynamoDB
    const form = this.createHiddenForm(
      {
        "Ds_SignatureVersion": bbvaData["Ds_SignatureVersion"],
        "Ds_MerchantParameters": bbvaData["Ds_MerchantParameters"],
        "Ds_Signature": bbvaData["Ds_Signature"],
      },
      bbvaData["tpv_redirect_url"],
    );

    document.body.appendChild(form);
    form.submit();
  }


  private createHiddenForm(
    parameters: Record<string, any>,
    url: string,
    target: string = '_top',
    method: string = 'POST',
  ) {
    const form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('target', target);
    form.setAttribute('action', url);
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        form.appendChild(this.createHiddenInput(key, value));
      }
    }
    return form;
  }

  private createHiddenInput(name: string, value: string) {
    const el = document.createElement('input');
    el.setAttribute('type', 'hidden');
    el.setAttribute('name', name);
    el.setAttribute('value', value);
    return el;
  }
}


// BbvaPaymentParams interface
interface BBVAPaymentParams {
  AMOUNT: string;
  DS_MERCHANT_AMOUNT: string;
  DS_MERCHANT_CURRENCY: string;
  DS_MERCHANT_MERCHANTCODE: string;
  DS_MERCHANT_TERMINAL: string;
  DS_MERCHANT_TRANSACTIONTYPE: string;
  DS_MERCHANT_URLKO: string;
  DS_MERCHANT_URLOK: string;
  DS_MERCHANT_MERCHANTURL: string;
}


class BBVAPaymentParams {
  constructor(amount: string, private configService: ConfigService) {
    // Las últimas posiciones hacen referencia a los decimales de la moneda.Ejemplo: En el caso del EURO 43,45 Euros habría que indicar 4345.
    this.AMOUNT = amount;
    this.DS_MERCHANT_AMOUNT = (parseInt(amount) * 100).toString()
    this.DS_MERCHANT_CURRENCY = this.configService.config.bbva.currency_code;
    this.DS_MERCHANT_MERCHANTCODE = this.configService.config.bbva.merchant_code;
    this.DS_MERCHANT_TERMINAL = this.configService.config.bbva.merchant_terminal_id;
    this.DS_MERCHANT_TRANSACTIONTYPE = this.configService.config.bbva.transaction_type;
    this.DS_MERCHANT_URLKO = this.configService.config.bbva.notification_url_ko;
    this.DS_MERCHANT_URLOK = this.configService.config.bbva.notification_url_ok;
    this.DS_MERCHANT_MERCHANTURL = this.configService.config.bbva.notification_url_api;
  }
}
