import { Component, Inject, inject } from "@angular/core";
import { ConfigService } from "../../config.service";
import { TUI_IS_MOBILE } from "@taiga-ui/cdk";
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { TUI_COUNTRIES_MASKS } from '@taiga-ui/kit';
import { AuthService } from "src/app/services/auth.service";
import { MatDialog } from '@angular/material/dialog';
import { AWSLambda } from 'src/app/AWSLambda.service';

import { CountriesModalComponent } from '../rates/countries-modal.component';
import { RatesModalComponent } from '../rates/rates-modal.component';
import { Rate, RatesModal } from '../rates/rates.model';
import { getCountryInfo } from '../recharges/recharges.countries';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: [],
  styles: [
    `
      .loading-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(
          0,
          0,
          0,
          0.5
        ); /* semi-transparent black background */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999; /* Ensure it's on top of other content */
      }
    `,
  ],
})


export class HomeComponent {
  showSpinner = false;
  window = window;
  dialog = inject(MatDialog);
  lambda = inject(AWSLambda);

  
  transfersUrl = `${inject(ConfigService).config.transfersUrl}?tracker=${inject(AuthService).getUser().id}`;
  transfersAccountURL = `${inject(ConfigService).config.transfersAccountURL}?tracker=${inject(AuthService).getUser().id}`;
  constructor(
    @Inject(TUI_IS_MOBILE) readonly isMobile: boolean,
    @Inject(TUI_COUNTRIES_MASKS)
    private readonly countriesMasks: Record<TuiCountryIsoCode, string>
    ) {
      //super();
    }

  openCountriesModal() {
    this.showSpinner = true;
    this.dialog
      .open(CountriesModalComponent, {
        width: this.isMobile ? '85%' : '30%',
        panelClass: 'zendy-modal',
      })
      .afterClosed()
      .subscribe(async (countryCode: TuiCountryIsoCode) => {
        const rates: Rate = await this.getPricing(countryCode);
        if (!rates) return;
        this.openRatesModal(countryCode, rates);
      });
    this.showSpinner = false;
  }
  
  openRatesModal(country: TuiCountryIsoCode, rates: Rate) {
    this.showSpinner = true;
    const data: RatesModal = {
      country,
      pricing: rates.pricing,
      prefix: rates.to,
    };
    this.dialog.open(RatesModalComponent, {
      width: this.isMobile ? '95%' : '30%',
      panelClass: 'zendy-modal',
      data,
    });
    this.showSpinner = false;
  }
  
  async getPricing(to: TuiCountryIsoCode) {
    if (this.countriesMasks[to]) {
      const countryCode = this.countriesMasks[to].replace(/[()\s#-]/g, '');
  
      const isoCode = getCountryInfo(countryCode, to, "ISO-3");
      
      return await this.lambda.getLambdaFunction('lambda', '/pricing/calls', {
        queryStringParameters: {
          from: '+34',
          to: countryCode,
          via: 'signalwire',
          filter: isoCode // Regexpress - aqui deberia ir el ISO CODE o el nombre del pais en ingles.
        },
      });
    }
  }
}



