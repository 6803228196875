import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  TuiActiveZoneModule,
  TuiLetModule,
  TuiMapperPipeModule,
} from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListComponent,
  TuiDataListModule,
  TuiFlagPipe,
  TuiFlagPipeModule,
  TuiGroupModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiPrimitiveTextfieldModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiWrapperModule,
  tuiIsEditingKey,
} from '@taiga-ui/core';
import {
  TuiArrowModule,
  TuiInputPhoneInternationalComponent,
  TuiInputPhoneModule,
  TuiSortCountriesPipeModule,
  TuiToCountryCodePipe,
} from '@taiga-ui/kit';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { FilterCountriesPipe } from './filter-countries.pipe';

export const IMPORTS = [
  CommonModule,
  FormsModule,
  PolymorpheusModule,
  TuiSvgModule,
  TuiInputPhoneModule,
  TuiGroupModule,
  TuiPrimitiveTextfieldModule,
  TuiTextfieldControllerModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiDataListModule,
  TuiArrowModule,
  TuiWrapperModule,
  TuiLetModule,
  TuiMapperPipeModule,
  TuiActiveZoneModule,
  TuiFlagPipeModule,
];
@Component({
  selector: 'zendy-intl-phone',
  standalone: true,
  template: `
    <tui-hosted-dropdown
      *ngIf="countriesNames$ | async as countriesNames"
      class="t-hosted-dropdown"
      [canOpen]="!readOnly"
      [content]="dropdown"
      [(open)]="open"
      (tuiActiveZoneChange)="onActiveZone($event)"
    >
      <div tuiGroup>
        <tui-primitive-textfield
          tuiHintContent=""
          tuiTextfieldPostfix=""
          tuiTextfieldPrefix=""
          class="t-country-select tui-group__auto-width-item !sticky top-0 left-0 right-0"
          [disabled]="disabled"
          [editable]="false"
          [focusable]="focusable"
          [pseudoFocus]="open || null"
          [readOnly]="readOnly"
          [tuiTextfieldCustomContent]="countryValueContent"
          [tuiTextfieldIcon]="icon"
          [tuiTextfieldLabelOutside]="true"
        ></tui-primitive-textfield>
        <tui-input-phone
          class="t-input-phone tui-group__auto-width-item w-full"
          [countryCode]="inputPhoneCountryCode"
          [disabled]="disabled"
          [focusable]="focusable"
          [phoneMaskAfterCountryCode]="phoneMaskAfterCountryCode"
          [pseudoFocus]="pseudoFocus"
          [pseudoHover]="pseudoHover"
          [pseudoInvalid]="computedInvalid"
          [readOnly]="readOnly"
          [(ngModel)]="value"
        >
          <ng-content></ng-content>
          <input autocomplete="new-password" tuiTextfield />
        </tui-input-phone>
      </div>

      <ng-template #dropdown>
        <tui-primitive-textfield
          #input
          tuiTextfieldIconLeft="tuiIconSearchLarge"
          [tuiTextfieldLabelOutside]="true"
          [(value)]="val"
          class="m-2 !sticky top-0 left-0 right-0"
          (keydown.arrowDown.prevent)="onArrowDown(list, $event)"
        >
          <input tuiTextfield type="text" autocomplete="off" />
        </tui-primitive-textfield>

        <tui-data-list
          class="w-full"
          #list
          [emptyContent]="'No matches found'"
          (keydown)="onKeyDown($event.key, input.nativeFocusableElement)"
        >
          <button
            *ngFor="
              let item of countries.concat()
                | filterCountries : countriesNames : val
                | tuiSortCountries
                | async
            "
            tuiOption
            (click)="onItemClick(item)"
          >
            <img alt="" class="t-country-item-flag" [src]="getFlagPath(item)" />
            <span class="t-country-item-name">
              {{ countriesNames[item] }}
            </span>
            <span class="t-country-item-code">
              {{ item | tuiMapper : isoToCountryCodeMapper }}
            </span>
          </button>
        </tui-data-list>
      </ng-template>

      <ng-template #countryValueContent>
        <!-- eslint-disable @html-eslint/require-img-alt -->
        <img
          class="t-flag"
          [alt]="countriesNames[countryIsoCode]"
          [src]="countryIsoCode | tuiFlag"
        />
      </ng-template>

      <ng-template #icon>
        <div appearance="icon" tuiWrapper>
          <ng-container *polymorpheusOutlet="arrow"></ng-container>
        </div>
      </ng-template>
    </tui-hosted-dropdown>
  `,
  imports: [
    IMPORTS,
    TuiButtonModule,
    FilterCountriesPipe,
    TuiSortCountriesPipeModule,
  ],
  providers: [TuiFlagPipe, TuiToCountryCodePipe],
  styleUrls: ['./zendy-intl-phone.component.less'],
})
export class ZendyIntlPhoneComponent extends TuiInputPhoneInternationalComponent {
  val = '';

  onArrowDown<T>(list: TuiDataListComponent<T>, event: Event): void {
    list.onFocus(event, true);
  }

  onKeyDown(key: string, element: HTMLElement | null): void {
    if (element && tuiIsEditingKey(key)) {
      element.focus({ preventScroll: true });
    }
  }
}
