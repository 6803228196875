<div class="container mx-auto p-4">
  <h2 class="text-xl font-bold text-center">
    <mat-icon (click)="goBack()" class="float-left ms-4 cursor-pointer">arrow_back_ios</mat-icon>{{ 'ESIM.DETAILS.TITLE' | translate }}</h2>

  <div *ngIf="details">
    <div class="container mx-auto p-4">
      <!-- Contenedor del eSIM -->
      <div class="esim-card-container">
        <mat-card class="esim-card">
          <div class="bg-white rounded-full bg-white absolute right-0 mr-4 p-2" style="height:fit-content;" >
            <span class=" flag large {{ country }} block"></span>
          </div>
          <mat-card-header class="mb-2">
            <div class="esim-details-image-container relative">
              <img src="https://cdn-dev.zendy.tel/images/Esim/EsimCard@2x.png" alt="eSIM Image" class="esim-details-image" />
              <span class="esim-details-country-label">{{ country }}</span> <!-- Aquí agregamos el país -->
            </div>
          </mat-card-header>

          <mat-card-content>
            <p><strong>{{ country }}</strong></p>
            <p><strong>ICCID: </strong>{{ details.iccid }}</p>
            <p><strong>{{ 'ESIM.OPERATOR' | translate }}: </strong> {{ operator }}</p>
          </mat-card-content>

          <mat-card-actions class="mat-card-actions">
            <button mat-button class="recharge-button" (click)="onRecharge(details.iccid)">
              {{ 'ESIM.DETAILS.RECHARGE_NOW' | translate }}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <!-- Data Usage Section -->
    <div class="usage-section grid-cols-3">
      <!-- Data -->
      <mat-card *ngIf="(usageData?.total != 0 && details.iccid != '')" class="usage-card w-full">
        <mat-card-header>
          <div class="header-content flex justify-between">
            <mat-card-title class="usage-title">{{ 'ESIM.DETAILS.DATA' | translate }}</mat-card-title>
            <div class="usage-info">
              {{ 'ESIM.DETAILS.USED' | translate }}:
              {{ convertDataUnits(usageData?.total - usageData?.remaining || 0) }}
              {{ 'ESIM.OF' | translate }} {{ convertDataUnits(usageData?.total || 0) }}
            </div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <mat-progress-bar mode="determinate"
                            [value]="calculatePercentage(usageData?.total || 0, usageData?.remaining || 0)"
                            class="custom-progress-bar">
          </mat-progress-bar>
          <p class="remaining-info">
            {{ 'ESIM.DETAILS.YOU_HAVE' | translate }} {{ convertDataUnits(usageData?.remaining || 0) }}
          </p>
        </mat-card-content>
      </mat-card>

      <!-- Voice -->
      <mat-card *ngIf="usageData?.total_voice != 0" class="usage-card w-full">
        <mat-card-header>
          <div class="header-content flex justify-between">
            <mat-card-title class="usage-title">{{ 'ESIM.DETAILS.VOICE' | translate }}</mat-card-title>
            <div class="usage-info">
              {{ 'ESIM.DETAILS.USED' | translate }}:
              {{ usageData.total_voice - usageData.remaining_voice }} min
              {{ 'ESIM.OF' | translate }} {{ usageData.total_voice }} min
            </div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <mat-progress-bar mode="determinate"
                            [value]="calculatePercentage(usageData.total_voice, usageData.remaining_voice)">
          </mat-progress-bar>
          <p class="remaining-info">
            {{ 'ESIM.DETAILS.YOU_HAVE' | translate }} {{ usageData.remaining_voice }}
            {{ 'ESIM.DETAILS.MINUTES' | translate }}
          </p>
        </mat-card-content>
      </mat-card>

      <!-- SMS -->
      <mat-card *ngIf="usageData?.total_text != 0" class="usage-card w-full">
        <mat-card-header>
          <div class="header-content flex justify-between">
            <mat-card-title class="usage-title">SMS</mat-card-title>
            <div class="usage-info">
              {{ 'ESIM.DETAILS.USED' | translate }}:
              {{ usageData.total_text - usageData.remaining_text }} SMS
              {{ 'ESIM.OF' | translate }} {{ usageData.total_text }}
            </div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <mat-progress-bar mode="determinate"
                            [value]="calculatePercentage(usageData.total_text, usageData.remaining_text)">
          </mat-progress-bar>
          <p class="remaining-info">
            {{ 'ESIM.DETAILS.YOU_HAVE' | translate }} {{ usageData.remaining_text }}
            {{ 'ESIM.DETAILS.MESSAGES' | translate }}
          </p>
        </mat-card-content>
      </mat-card>

    </div>
  </div>

  <div class="container mx-auto p-4">

    <!-- QR Code Section -->
    <div class="qr-install-container w-2/5">
      <mat-card class="qr-card">
        <h2 class="text-xl font-bold">{{ 'ESIM.DETAILS.QR_AND_INSTALATION' | translate }}</h2>
        <mat-card-header>
          <!-- <mat-card-title class="text-red-600 font-bold">QR e instalación</mat-card-title> -->
        </mat-card-header>
        <mat-card-content class="mb-4">
          <!-- <p class="font-bold text-center text-lg">
              {{ 'ESIM.DETAILS.QR_TEXT' | translate }}
          </p> -->
          <div class="qr-image-container">
            <img [src]="qrCodeUrl" alt="QR Code" class="qr-code-image">
          </div>
          <p class="font-bold text-center text-lg">
            {{ 'ESIM.DETAILS.INSTALATION_INSTRUCTIONS' | translate }}
          </p>
          <div class="installation-buttons">
            <button mat-button class="install-button" (click)="selectPlatform('android')">
              <mat-icon>android</mat-icon> Android
            </button>
            <button mat-button class="install-button" (click)="selectPlatform('ios')">
              <mat-icon>apple</mat-icon> iOS
            </button>
          </div>
        </mat-card-content>

        <!-- Expansion Panel for Instructions -->
        <mat-expansion-panel *ngIf="selectedPlatform && installationGuide[selectedPlatform]"
                             class="installation-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'ESIM.DETAILS.INSTALATION_INSTRUCTIONS_FOR' | translate }} {{
 selectedPlatform |
                            uppercase
              }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="installation-content">
            <div class="mt-2">
              <h4>{{ 'ESIM.DETAILS.QR_AND_INSTALATION' | translate }}</h4>
              <ul>
                <li *ngFor="let step of installationGuide[selectedPlatform].installation_via_qr_code.steps | keyvalue">
                  <label class="font-bold">
                    {{ 'ESIM.DETAILS.STEP' | translate }} {{
 step.key
                    }}:
                  </label> {{ step.value }}
                </li>
              </ul>
            </div>

            <div class="mt-2">
              <h4>{{ 'ESIM.DETAILS.MANUAL_INSTALATION' | translate }}</h4>
              <ul>
                <li *ngFor="let step of installationGuide[selectedPlatform].installation_manual.steps | keyvalue">
                  <label class="font-bold">
                    {{ 'ESIM.DETAILS.STEP' | translate }} {{
 step.key
                    }}:
                  </label> {{ step.value }}
                </li>
              </ul>
            </div>

            <div class="mt-2">
              <h4>{{ 'ESIM.DETAILS.NETWORK_SETUP' | translate }}</h4>
              <ul>
                <li *ngFor="let step of installationGuide[selectedPlatform].network_setup.steps | keyvalue">
                  <label class="font-bold">
                    {{ 'ESIM.DETAILS.STEP' | translate }} {{
 step.key
                    }}:
                  </label> {{ step.value }}
                </li>
              </ul>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-card>
    </div>

    <div class="card-details-container w-3/5">
      <h2 class="text-xl font-bold">{{ 'ESIM.DETAILS.ADDITIONAL_INFORMATION' | translate }}</h2>
     {{ details | json }}
    </div>
  </div>

  <div class="container mx-auto p-4 text-center">
    <button mat-raised-button color="primary" (click)="goBack()">{{ 'ESIM.DETAILS.BTN_BACK' | translate }}</button>
  </div>
