<div
  class="p-2 bg-gray-opacity rounded-2xl flex justify-around md:justify-center md:gap-4 md:p-4"
>
  <img
    class="relative rotate-minus12"
    width="73px"
    src="./assets/icons/card.svg"
  />
  <span
    class="tracking-tight text-gray flex items-center justify-between gap-2 text-base"
  >
    <div class="text-center">
      {{ 'BALANCE.TITLE' | translate }}
    </div>
    <span class="text-light-red text-base md:text-3xl flex items-center justify-between gap-2">
      {{ balance | currency : currency : 'symbol' }}
      <a *ngIf="!router.url.includes('recharges')" class="text-sm text-light-red" routerLink="/recharges">
        {{
        'BALANCE.ADD' | translate
        }}
      </a>
    </span>
  </span>
</div>
