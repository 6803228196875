/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

import { Injectable } from "@angular/core";
import { RestAPI } from "@aws-amplify/api-rest";
import { AuthService } from 'src/app/services/auth.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Injectable({ providedIn: 'root' }) // Agrega el decorador @Injectable() y especifica providedIn: 'root'
export class AWSLambda {

  name: string | undefined;
  phone_number: string | undefined;
  email: string | undefined;
  sub: string | undefined;

  constructor(
    private authenticatorSvc: AuthenticatorService,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    await this.auth.refresh()
    const user = await this.authenticatorSvc.user;

    if (user.attributes?.['name'] != undefined) this.name = user.attributes?.['name'];
    if (user.attributes?.['phone_number'] != undefined) this.phone_number = user.attributes?.['phone_number'];
    if (user.attributes?.['email'] != undefined) this.email = user.attributes?.['email'];
    if (user.attributes?.['sub'] != undefined) this.sub = user.attributes?.['sub'];
  }

  async getLambdaFunction(apiName: string = "MyApiName", path: string = '/path', init: object = {
      headers: {
        "Content-Type": "application/json",
        "username":this.name,
        "phone_number":this.phone_number,
        "email":this.email,
        "sub":this.sub,
      }
    }): Promise<any> {
    return RestAPI.get(apiName, path, init).catch( e => {
      throw Error(JSON.stringify(e.response.data))
    })
  }
}
