<app-zendy-header *ngIf="isMobile"></app-zendy-header>

<div *ngIf="!isMobile"  class="flex alertbg" style="overflow: hidden;">
  <div class="texto-movimiento" routerLink="/profile">
      <span  class="animacion-marquesina">
        {{'HOME.PROMOTION' | translate }}</span>
  </div>
</div>
<div class="flex flex-col justify-center items-center w-full">
  <img *ngIf="isMobile" [title]="'HOME.GOTOPROFILE' | translate"  src="https://cdn-dev.zendy.tel/images/Powered-by-T.png" />
<img *ngIf="isMobile" [title]="'HOME.GOTOPR OFILE' | translate"  src="https://cdn-dev.zendy.tel/images/Promo-Zendy-3-24%402x.png" routerLink="/profile"/>

</div>
  
<div *ngIf="isMobile" class="flex flex-col md:flex-row md:justify-between md:gap-8 md:max-w-md md:m-auto">
  <div class="w-full">
    <div class="flex items-center justify-between">
      <h2 class="!m-0">{{ 'HOME.LAST_CALL' | translate }}</h2>
      <a routerLink="/history" class="text-light-red text-sm">{{
        'COMMON.SEE_ALL' | translate
      }}</a>
    </div>
    <div class="w-full" style="max-width:400PX;">
      <app-history-list class="w-full" ></app-history-list>
    </div>
    <div class="flex justify-center mt-6 mb-10">
      <div class="flex justify-center mt-6 mb-10">
        <app-button hasIcon="hasIcon" (click)="openCountriesModal()"
          ><img width="24px" height="24px" src="./assets/icons/rates-button.svg" />
          {{ 'RECHARGES.RATES_BUTTON' | translate }}
        </app-button>
      </div>
    </div>
  </div>
  <div *ngIf="false" class="w-full">
    <div class="flex items-center justify-between">
      <h2 class="m-0">{{ 'HOME.LAST_TRANSFER' | translate }}</h2>
      <a routerLink="/transfers" class="text-light-red text-sm">{{
        'COMMON.SEE_ALL' | translate
      }}</a>
    </div>
    <div class="flex justify-center mt-6">
      <app-button
        hasIcon="hasIcon"
        (click)="window.open(transfersAccountURL, '_blank')"
        ><img
          width="24px"
          height="24px"
          src="./assets/icons/button-transfer.svg"
        />{{ 'HOME.BUTTON_LIST_TRANSFER' | translate }}</app-button
      >
      </div>
      <div class="flex justify-center mt-6">
      <app-button
        hasIcon="hasIcon"
        (click)="window.open(transfersUrl, '_blank')"
        ><img
          width="24px"
          height="24px"
          src="./assets/icons/button-transfer.svg"
        />{{ 'HOME.BUTTON_TRANSFER' | translate }}</app-button
      >
    </div>
  </div>
</div>


<app-zendy-header-desktop *ngIf="!isMobile"></app-zendy-header-desktop>
<div class="flex justify-center">
  <img class="w-200" [title]="'HOME.GOTOPROFILE' | translate" src="https://cdn-dev.zendy.tel/images/Powered-by-T.png" />
</div>
<div *ngIf="!isMobile" class="flex flex-col md:flex-row md:justify-center md:gap-8 md:m-auto">
   <div *ngIf="!isMobile" class="w-full md:w-1/2 flex justify-center items-center">
    <!-- Bloque a la izquierda -->
    <div class="flex flex-col justify-center items-center">
      <div class="mt-6 mb-4">
        <h2 class="!m-0">{{ 'HOME.LAST_CALL' | translate }}</h2>
        <a routerLink="/history" class="text-light-red text-sm">{{ 'COMMON.SEE_ALL' | translate }}</a>
      </div>
      <app-history-list  class="md:w-2/3" ></app-history-list>
      <div class="mt-4">
        <app-button hasIcon="hasIcon" (click)="openCountriesModal()">
          <img width="24px" height="24px" src="./assets/icons/rates-button.svg" />
          {{ 'RECHARGES.RATES_BUTTON' | translate }} 
        </app-button>
      </div>
    </div>
  </div>

    <div *ngIf="!isMobile" class="w-full md:w-1/2 flex justify-center items-center">
      <!-- Bloque a la derecha -->
      <div class="flex flex-col justify-center items-center w-full">
        <img class="w-full aspect-video" [title]="'HOME.GOTOPROFILE' | translate" src="https://cdn-dev.zendy.tel/images/Promo-Zendy-3-24@3x.png" routerLink="/profile/edit"/>
        
      </div>
    </div>

  <div *ngIf="false" class="w-full">
    <div class="flex items-center justify-between">
      <h2 class="m-0">{{ 'HOME.LAST_TRANSFER' | translate }}</h2>
      <a routerLink="/transfers" class="text-light-red text-sm">{{
        'COMMON.SEE_ALL' | translate
      }}</a>
    </div>
    <div class="flex justify-center mt-6">
      <app-button
        hasIcon="hasIcon"
        (click)="window.open(transfersAccountURL, '_blank')"
        ><img
          width="24px"
          height="24px"
          src="./assets/icons/button-transfer.svg"
        />{{ 'HOME.BUTTON_LIST_TRANSFER' | translate }}</app-button
      >
      </div>
      <div class="flex justify-center mt-6">
      <app-button
        hasIcon="hasIcon"
        (click)="window.open(transfersUrl, '_blank')"
        ><img
          width="24px"
          height="24px"
          src="./assets/icons/button-transfer.svg"
        />{{ 'HOME.BUTTON_TRANSFER' | translate }}</app-button
      >
    </div>
  </div>
</div>

<div class="loading-mask" *ngIf="showSpinner">
  <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
</div>



