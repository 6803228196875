import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EsimService } from '../esim.service'; // Importa tu servicio
import { MatSnackBar } from '@angular/material/snack-bar'; // Importa el MatSnackBar
import { MatDialog } from '@angular/material/dialog'; // Importa MatDialog para abrir modales
import { PurchaseDetailsModalComponent } from '../purchase-details-modal/purchase-details-modal.component'; // Importa el modal de detalles de compra

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss']
})
export class TopupComponent implements OnInit {
  iccid: string | null = '';
  esimDetails: any = {};  // Detalles del eSIM
  topups: any[] = [];     // Lista de topups
  isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private esimService: EsimService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // obtener el iccid de la URL
    this.iccid = this.route.snapshot.paramMap.get('iccid');

    if (this.iccid) {
      this.getEsimDetails(this.iccid);
      this.getAvailableTopups(this.iccid);
    }
  }

  getEsimDetails(iccid: string): void {
    this.esimService.getEsimDetails(iccid).then((details) => {
      this.esimDetails = details;
    });
  }

  getAvailableTopups(iccid: string): void {
    this.esimService.topupList(iccid).then((topups) => {
      this.topups = topups;
      //console.log(this.topups);
    }).catch(error => {
      console.error('Error al obtener la lista de topups:', error);
    });
  }

  // Método para manejar la recarga (topup)
  async submitTopUp(topupId: string): Promise<void> {
    const amount = '0';
    const tpvType = 'Esim';

    this.isSubmitting = true;
    try {
      const order = await this.esimService.submitTopUpOrder(amount, tpvType, topupId, this.iccid!);
      this.snackBar.open('Recarga exitosa!', 'Cerrar', { duration: 3000 });

      // Abre el modal de detalles de compra
      this.openPurchaseDetailsModal(order);
    } catch (error) {
      console.error('Error al realizar la recarga', error);
      this.snackBar.open('Error al realizar la recarga. Por favor intenta nuevamente.', 'Cerrar', { duration: 3000 });
    } finally {
      this.isSubmitting = false;
    }
  }

  // Método para abrir el modal de detalles de la compra
  openPurchaseDetailsModal(order: any): void {
    this.dialog.open(PurchaseDetailsModalComponent, {
      data: { order }
    });
  }
}
